<template>
    <div>
        <b-alert variant="warning" :show="emptyPrecification">
            <div class="alert-body">
                <span>A precificação não foi definida no cadastro da campanha, informe o departamento da região ou entre em contato com o suporte.</span>
            </div>
        </b-alert>
        
        <div v-if="loadingCompanyType" class="d-flex justify-content-center mt-4">
            <div class="spinner-border text-custom-blue" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <b-row v-else>
            <b-col lg="7">
                <b-form>
                    <app-collapse>
                        <app-collapse-item
                            v-for="(price, priceIndex) in formPrecification"
                            :key="priceIndex"
                            :isVisible="priceIndex == 0 && price.priceNoPromotion.length > 0"
                            :title="price.companyType" 
                            class="mb-2"
                        >
                            <template slot="header">
                                <h4 class="text-custom-blue mb-0">{{ price.companyType }}</h4>
                            </template>

                            <section class="pricingForm pt-0 pb-1 px-1 max-width-form">
                                <b-row v-if="price.priceNoPromotion.length > 0">
                                    <b-col 
                                        v-for="(priceInputsNoPromotion, index) in price.priceNoPromotion" 
                                        :key="index"
                                        sm="12"
                                        md="4"
                                        :class="{'pr-0': HelperLayout.adjustLayoutRight(price.priceNoPromotion, index)}"
                                    >
                                        <b-form-group>
                                            <label :for="'price-'+priceInputsNoPromotion.category">{{ priceInputsNoPromotion.category }}</label>
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <div 
                                                        :class="{
                                                            'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                            'disabled-append': priceInputsNoPromotion.notApplicable
                                                        }"
                                                    >
                                                        <b-img :src="Ricon" alt="Icone que representa o real"></b-img>
                                                    </div>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="priceInputsNoPromotion.value"
                                                    @keydown="$event.key === '-' && $event.preventDefault()"
                                                    :class="{
                                                        'border-color-input': true,
                                                        'border-color-input-disabled': true
                                                    }"
                                                    v-money="moneyMask"
                                                    :id="'price-'+priceInputsNoPromotion.category"
                                                    placeholder="00,00"
                                                    :disabled="true"
                                                />
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="hasIncentivePromotion && price.priceWithPromotion.length > 0" class="background-custom-blue p-1">
                                    <h5 class="w-100">Com fomento</h5>
                                    <b-col 
                                        v-for="(priceInputsWithPromotion, index) in price.priceWithPromotion"  
                                        :key="index"
                                        sm="12"
                                        md="4"
                                        :class="{
                                            'pr-0': HelperLayout.adjustLayoutRightWithPromotion(price.priceWithPromotion, index), 
                                            'pl-0': HelperLayout.adjustLayoutLeft(price.priceWithPromotion, index)
                                        }"
                                    >
                                        <b-form-group>
                                            <label :for="'price-'+priceInputsWithPromotion.category">{{ priceInputsWithPromotion.category }}</label>
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <div  
                                                        :class="{
                                                            'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                            'disabled-append': priceInputsWithPromotion.notApplicable
                                                        }"
                                                    >
                                                        <b-img :src="Ricon" alt="Icone que representa o real"></b-img>
                                                    </div>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="priceInputsWithPromotion.value"
                                                    @keydown="$event.key === '-' && $event.preventDefault()"
                                                    :class="{
                                                        'border-color-input': true, 
                                                        'border-color-input-disabled': true
                                                    }"
                                                    v-money="moneyMask"
                                                    :id="'price-'+priceInputsWithPromotion.category"
                                                    placeholder="00,00"
                                                    :disabled="true"
                                                />
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </section>
                        </app-collapse-item>
                    </app-collapse>
                </b-form>
            </b-col>
            <b-col lg="4" class="separator ml-2 pl-2 pb-5">
                <SummaryPricing 
                    :formPrecification="formPrecification"
                    :hasIncentivePromotion="hasIncentivePromotion"
                    :hasNumberOfDoses="hasDosesNoPromotion"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BForm, 
BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, 
BButton, BImg, BFormCheckbox, BAlert, BBadge } from 'bootstrap-vue';
import Ricon from '@/assets/images/icons/RIcon.svg';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import HelperLayout from '@/views/custom-pages/Campanhas/Services/AdjustLayoutPricesService.js';

import SummaryPricing from '@/views/components/custom/summary-pricing/SummaryPricing.vue';
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';

export default {
    components: {
        AppCollapse, AppCollapseItem,
        BRow, BCol, BForm, BFormGroup,
        BFormInput, BButton, BInputGroup, BBadge,
        BInputGroupPrepend, BImg, BFormCheckbox, 
        BAlert, SummaryPricing, ErrorNumberOfDoses
    },
    props: {
        idCampanha: {
            required: true,
            type: Number|null
        },
        idVacccine: {
            required: true,
            type: Number|null
        }
    },

    data() {
        return {
            moneyMask: {
                decimal: ',',
                thousands: '.',
                precision: 2,
            },
            modalErrorPrecification: {
                showModal: false,
                type: 'error-precification'
            },
            Ricon,
            HelperLayout,
            campaignId: this.$props.idCampanha,
            selectedIdVaccine: this.$props.idVacccine,
            loadingCompanyType: true,
            formPrecification: [],
            tiposEMpresaCategorias: [],
            savedPrecification: false,
            hasDosesNoPromotion: true,
            hasIncentivePromotion: false,
            emptyPrecification: false
        }
    },

    mounted() {
        this.loadPrice();
    },

    methods: {
        async loadPrice() {
            await this.$http.get(this.$api.adminRegiaoTipoPrecoCampanhaVacina(this.campaignId, this.selectedIdVaccine))
            .then(({data}) => {
                this.tiposEmpresaCategorias = data;
                this.makeFormPrecification();
            })
        },
        makeFormPrecification() {
            this.formPrecification = [];

            this.tiposEmpresaCategorias.map((tipoEmpresa) => {
                const dataCategory = [];

                tipoEmpresa.categorias.map(({id_categoria, valor_dose, nome_categoria}) => {
                    dataCategory.push({
                        value: valor_dose.toFixed(2),
                        category: nome_categoria,
                        idCategoria: id_categoria,
                        notApplicable: false
                    });
                });
                let typePrice = this.formPrecification.filter((preco) => preco.idTypePrice == tipoEmpresa.id_tipo_preco);

                if(typePrice.length == 0) {
                    if(tipoEmpresa.id_incentivo == 1){
                        this.formPrecification.push({
                            idTypePrice: tipoEmpresa.id_tipo_preco,
                            companyType: tipoEmpresa.descricao,
                            priceNoPromotion: dataCategory,
                            priceWithPromotion: [],
                        });
                    }

                    if(tipoEmpresa.id_incentivo == 2){
                        this.hasIncentivePromotion = true;
                        this.formPrecification.push({
                            idTypePrice: tipoEmpresa.id_tipo_preco,
                            companyType: tipoEmpresa.descricao,
                            priceNoPromotion: [],
                            priceWithPromotion: dataCategory
                        });
                    }
                }else{
                    if(tipoEmpresa.id_incentivo == 1){
                        typePrice[0].priceNoPromotion = dataCategory;
                    }
                    if(tipoEmpresa.id_incentivo == 2){
                        this.hasIncentivePromotion = true;
                        typePrice[0].priceWithPromotion = dataCategory;
                    }
                }

            });

            this.loadingCompanyType = false;
        },
    },

    watch: {
        idVacccine(idVaccine) {
            this.selectedIdVaccine = idVaccine;
        },
        formPrecification() {
            if(this.formPrecification.length == 0){
                this.emptyPrecification = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.disabled-collapse {
    pointer-events:none;
    background-color:#ededed
}

.separator {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}

.background-custom-blue {
    background-color: rgba(39, 114, 192, 0.12);
    border-radius: 6px;
}

.custom-append-input {
    border-radius: 0.357rem;
    z-index: 5;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.disabled-append {
    background: $placeholder-color !important;
}

.font-bold {
    font-weight: 800;
    font-size: 2rem;
}

.border-color-input {
    border-color: $custom-blue;
    margin-left: -4px;
}

.border-color-input-disabled {
    border-color: $placeholder-color !important;
}

.check-margin {
    margin-top: 5px !important;
}

.max-width-form {
    max-width: 85%;
}
</style>